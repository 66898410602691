.blogDetails {
    width: 100%;
    margin: 0;
    padding: 0;
}

.blogContentContainer {
    padding: 20px 10% 20px 10%;
    width: 100%;
    margin-top: 600px;
}

.blogTitle_OneBlog {
    margin: 75px 10% 65px 10%;
    width: 80%;
    font-family: freight-display-pro, serif;
    font-weight: 500;
    font-size: 75pt;
    color: rgb(15, 20, 52);
    text-align: center;
    position: absolute;
    top: 60px;
    text-shadow: 2px 2px 10px white;
}

.blogSubTitle_OneBlog {
    margin: 10px;
    font-size: 40pt;
    width: 100%;
    text-align: center;
    text-shadow: 2px 2px 10px white;
}

.blogContent {
    font-family: freight-display-pro, serif;
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 45px;
}

.blogBackgroundImage {
    width: 100%;
    position: absolute;
    top: 60px;
    z-index: -1;
    height: 600px;
    object-fit: cover;
}

.blogContentContainer > h1:first-child {
    font-family: freight-display-pro, serif;
    font-weight: 500;
    font-size: 28pt;
    color: #404040;
    margin-bottom: 45px;
}

.blog_Posts {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-wrap: wrap;
}

.blogs_BlogPost {
    width: 400px;
}

@media screen and (max-width: 1000px) {
    .blogContentContainer {
        margin-top: 450px;
        padding: 20px 2% 20px 2%;
    }

    .blogBackgroundImage {
        height: 450px;
    }

    .blogTitle_OneBlog {
        font-size: 28pt;
    }

    .blogSubTitle_OneBlog {
        font-size: 17pt;
    }

    .blogContentContainer > h1:first-child {
        font-size: 14pt;
    }
}

.centeredWrapper{
    text-shadow: none;
}