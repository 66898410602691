.wrapper {
    margin: 7% 20% 10px 20%;
    width: 60%;
    line-height: 2;
}

.calc_bck {
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    height: 30%;

    transform: skewY(-3deg);

    z-index: -1;

    background-color: #0edea4;
}

@media screen and (max-width: 900px) {
    .wrapper {
        margin: 25px 5% 10px 5%;
        width: 90%;
    }
}