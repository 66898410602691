.blogPostDetails {
    width: 100%;
    margin: 0;
    padding: 0;
}

.blogPostContentContainer {
    padding: 20px 10% 20px 10%;
    width: 100%;
}

.blogPostTitle_OneBlog {
    margin: 75px 10% 65px 10%;
    font-family: freight-display-pro, serif;
    font-weight: 500;
    font-size: 75pt;
    color: white;
    text-align: center;
    background-color: rgba(15, 20, 52, 0.61);
    position: absolute;
    top: 60px;
    padding-left: 10px;
    padding-right: 10px;
}

.blogPostContent img {
    max-width: 100%;
}

.blogPostContent {
    font-family: freight-display-pro, serif;
    font-size: 12pt;
    line-height: 1.4;
    margin-bottom: 45px;
}

.blogPostBackgroundImageWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-height: 700px;
    background-image: linear-gradient(180deg, #24307d, #47c1b9);
}

.blogPostBackgroundImage {
    z-index: 1;
    max-height: 700px;
    max-width: 100%;
    object-fit: cover;
}

.blogPostContentContainer > h1:first-child {
    font-family: freight-display-pro, serif;
    font-weight: 500;
    font-size: 46pt;
    color: #404040;
    margin-bottom: 45px;
}

@media screen and (max-width: 1200px) and (min-width: 600px){
    .blogPostTitle_OneBlog {
        font-size: 40pt;
        top: 80px;
    }

    .blogPostContentContainer > h1:first-child {
        font-size: 32pt;
        margin-bottom: 20px;
        text-align: center;
        width: 100%;
    }

}

@media screen and (max-width: 599px) {
    .blogPostTitle_OneBlog {
        font-size: 25pt;
        top: 80px;
    }

    .blogPostContentContainer > h1:first-child {
        font-size: 30pt;
        margin-bottom: 15px;
        text-align: center;
        width: 100%;
    }
}