.adatkezelesWrapper {
    margin: 25px 20% 10px 20%;
    width: 60%;
    line-height: 2;
}

@media screen and (max-width: 900px) {
    .adatkezelesWrapper {
        margin: 25px 5% 10px 5%;
        width: 90%;
    }
}