.LakasbiztositasExpansionHeading {
    font-size: 18pt;
    color: #0f1434;
    margin: 0;
}

.wrapper > h3 {
    width: 100%;
    text-align: center;
    color: #31308b;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 25pt;
}

@media screen and (max-width: 650px) {
    .LakasbiztositasExpansionHeading {
        font-size: 12pt;
    }

    .wrapper > h3 {
        font-size: 16pt;
    }
}