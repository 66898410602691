body {
  margin: 0;
  padding: 0;
  font-family: 'Camphor', 'Open Sans', 'Segoe UI', 'sans-serif', "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
}

code {
  font-family: "Pathway Gothic One", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

a, a:visited, a:hover, a:active {
  text-decoration: none !important;
  color: rgb(255, 89, 49);
}

a:hover {
  color: rgb(198, 72, 41);
}

@font-face {
  font-family: "Pathway Gothic One";
  src: url("assets/fonts/PathwayGothicOne-Regular.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Regular.ttf");
}

/*@font-face {*/
/*    font-family: "Helvetica Neue";*/
/*    src: url("assets/fonts/HelveticaNeue.ttf");*/
/*}*/

.page {
  padding-top: 60px;
  width: 100%;
  min-height: 70vh;
}

.button {
  text-decoration: none;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 17px;
  color: white !important;
  font-weight: bold;
  background-color: #138cc1;
  display: block;
  padding: 18px 20px 18px 20px;
  border-radius: 5px;
}

.button:hover {
  color: white;
  background-color: #17a4e2;
  cursor: pointer;
}

.centeredRowWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 0 10px 0 10px;
  text-align: center;
}

.centeredWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.cookies {
  width: 100%;

  padding: 10px;

  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  background-color: rgba(15, 20, 52, 0.7);
  position: fixed;
  bottom: 0;
  color: #f7ebca;

  z-index: 1000;
  line-height: 1.1rem;
  font-size: 14px;
}

.button_cookies {
  color: white !important;
  border: 1px solid white !important;
}

.cookies.hidden {
  display: none;
}

.MuiModal-root-30 {
  z-index: 20;
}
