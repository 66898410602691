.calculator{
    width: 100%;
    border: 1px solid rgb(20, 26, 68);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;

    margin: 10px 10px 50px 10px;
    padding: 10px 10% 15px 10%;
    position: relative;
    overflow: hidden;
}

.calc_bck1{
    position: absolute;
    bottom:5%;
    left:0;
    width: 120%;
    height: 15%;

    transform: skewY(3deg);

    z-index: -3;

    background-color: #ff5d00;
}

.calc_bck2{
    position: absolute;
    bottom:0;
    left:0;
    width: 120%;
    height: 15%;

    transform: skewY(3deg);

    z-index: -2;

    background-color: #619cff;
}
.calc_bck3{
    position: absolute;
    bottom:-40%;
    left:0;
    width: 120%;
    height: 50%;

    transform: skewY(3deg);

    z-index: -1;

    background-color: #0edea4;
}
