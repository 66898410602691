.usersContainer {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 10px;
    overflow-y: scroll;
}

.user {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #6d6d6d;
}

.email, .role, .modify, .delete {
    margin: 5px 15px 5px 15px;
    padding: 5px 15px 5px 15px;
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 15px;
    flex-grow: 2;
}

.modify, .delete {
    border: 1px solid black;
}

.modify:hover, .delete:hover {
    background-color: rgba(255, 18, 0, 0.48);
    cursor: pointer;
}

.userEditingModal {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.userEditingModalContent {
    background: white;

    padding: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.modalactions {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}