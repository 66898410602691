.changePassModal{
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.changePassForm{
    padding: 50px;
    background-color: white;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;

    overflow-y: scroll;
}


@media screen and (max-width: 1000px) {
    .changePassModal {
        padding: 0;
    }

    .changePassForm > h3 {
        font-size: 14pt;
    }

    .changePassForm {
        width: 95%;
        padding: 10px;
        height: 98vh;
        max-width: 100%;
    }

}

.loginpage, .dashboard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 75vh;
}

.dashboard {
    justify-content: flex-start;
    align-items: flex-start;
}

.loginform {
    padding: 50px 125px 50px 125px;
    box-shadow: 0 10px 10px rgba(15, 20, 52, 0.7);
    border-radius: 10px;
    background-color: azure;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button {
    padding: 10px 15px 10px 15px;
}

@media screen and (max-width: 750px) {
    .loginform {
        padding: 25px;
        width: 80%;
    }
}

.menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 15px;

    width: 250px;
    height: 100%;
    transition: width ease-in-out 0.6s, padding ease-in-out 0.6s;
    background-color: gainsboro;
}

.menu.closed {
    width: 50px;
    padding: 2px;
}

.menu.closed > .menuElement {
    justify-content: center;
}

.menuCloseButton {
    align-self: flex-end;
}

.menuElement {
    flex-grow: 1;
    max-height: 45px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    margin: 5px 0 5px 0;
    background-color: #ffffff;
    color: #0A0F25;
}

.menuElement:hover {
    background-color: #9c9c9c;
    cursor: pointer;
}

.logout {
    margin-top: auto;
}

.error{
    width: 100%;
    height: 35px;
    background-color: darkred;
    color: white;

    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 2px 10px 2px 10px;
    transition: height ease-in-out 0.2s;
}

.error.hidden{
    height:0;
    padding: 0;
}

.selectedMenuItemContainer{
    flex-grow: 2;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.selectedMenuItemTitle{
    height: 60px;
    background-color: gainsboro;

    border-left: 1px solid #b4b4b4;

    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding-left: 30px;

    font-size: 25px;
}

.selectedMenuItemContent{
    width: 100%;
    height: calc(100% - 60px);
    flex-grow: 2;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}