.Kapcsolat-wrapper {
    padding: 15px 10% 25px 10%;
    z-index: 10;
}

.Kapcsolat-wrapper > h2 {
    width: 100%;
    text-align: center;
    color: #31308b;
    font-family: "Helvetica Neue", sans-serif;
    font-size: 20pt;
}

.Kapcsolat-wrapper > p {
    font-family: "Open Sans", sans-serif;
    line-height: 1.8rem;
    font-size: 13pt;
}

@media screen and (max-width: 800px) {
    .Kapcsolat-wrapper {
        padding: 15px 3% 25px 3%;
    }

    .parallaxDiv {
        width: 90%;
    }
}

.Kapcsolat-team {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
}

.parallaxDiv {
    width: 70%;
    margin: 30px auto 30px auto;
    padding: 15px;
    font-family: Arial, sans-serif;
    font-size: 20px;
}

div.clickEffect {
    position: fixed;
    box-sizing: border-box;
    border-style: solid;
    border-color: #FFFFFF;
    border-radius: 50%;
    animation: clickEffect 1s ease-out;
    z-index: 99999;
}

@keyframes clickEffect {
    0% {
        opacity: 1;
        width: 0.5em;
        height: 0.5em;
        margin: -0.25em;
        border-width: 0.5em;
    }
    100% {
        opacity: 0.2;
        width: 15em;
        height: 15em;
        margin: -7.5em;
        border-width: 0.03em;
    }
}