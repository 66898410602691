.navigation-logo{
    display: block;
    height: 100%;
    width: auto;
}

.navigation-logo > img{
    box-sizing: border-box;
    height: 100%;
    padding: 10px;
}