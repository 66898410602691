.navigation-bar {
  width: 100vw;
  height: 60px;
  padding: 0 5px 5px 5px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 19;

  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  /*choose*/
  /*background-color: rgba(0, 0, 0, 0.22);*/
  background-color: rgb(255, 87, 49);
  /*background-image: linear-gradient(45deg, rgba(141, 21, 22, 0.9), rgba(18, 25, 69, 0.9), rgba(255, 68, 70, 0.9));*/
  transition: background-color 200ms ease-in-out;
}

.navigation-bar.homepage{
  background-color: rgba(0, 0, 0, 0.22);
}

.navigation-bar.scrolled {
  background-color: white;
  box-shadow: 0 3px 5px rgb(61, 61, 61);
}

.navigation-bar.scrolled > .navigation-menu a {
  /*color: white;*/
  font-size: 22px;
  font-family: "Pathway Gothic One", sans-serif;
  color: rgb(10, 15, 37);
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: border-radius 200ms ease-in-out;
}

.navigation-bar.scrolled > .navigation-menu a:hover {
  text-decoration: none;

  border-radius: 10px;

  border: 1px solid rgb(18, 25, 69);
  color: rgb(10, 15, 37);
  /*border: 1px solid rgb(180, 180, 180);*/
  /*color: white;*/
}

.navigation-bar > .logo-navlink {
  height: 100%;
  box-sizing: border-box;
}

.navigation-hamburger-menu {
  color: rgb(18, 25, 69);
}

.navigation-bar .navigation-hamburger-menu-container {
  display: none;
  align-self: flex-end;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 2;
  height: 100%;
}

.navigation-bar .navigation-menu {
  overflow: hidden;
  transform-origin: top right;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  padding-left: 10px;
}

.navigation-menu .navigation-menu-item {
  display: block;
  padding: 5px 20px 5px 20px;
  background-color: rgba(229, 64, 65, 0);
  margin: 0 10px 0 10px;

}

.navigation-menu a {
  /*color: white;*/
  font-size: 22px;
  font-family: "Pathway Gothic One", sans-serif;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: border-radius 200ms ease-in-out;
}

.navigation-menu a:hover {
  text-decoration: none;

  border-radius: 10px;

  border: 1px solid white;
  color: white;
  /*border: 1px solid rgb(180, 180, 180);*/
  /*color: white;*/
}

.navigation-menu a:active {
  background-color: rgba(255, 255, 255, 0.09);
}

.navigation-menu-item .dropdown-menu {
  border: 1px solid rgb(18, 25, 69);
}

@media screen and (max-width: 951px) {

  .navigation-bar .navigation-menu {
    transition: transform 400ms ease-in-out;
  }

  .navigation-bar .navigation-menu.closed {
    padding: 0;
    transform: scale(0, 0);
  }

  .navigation-bar .navigation-menu.opened {
    position: absolute;
    right: 45px;
    top: 45px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 20px 30px 20px 30px;

    border-radius: 10px;

    background-image: linear-gradient(40deg, rgba(255, 68, 70, 0.75), rgba(18, 25, 69, 0.75));
    z-index: 1;
  }

  .navigation-menu.opened .navigation-menu-item {
    color: white;
    font-size: 1.12em;
    padding: 15px 10px 15px 10px;
  }

  .navigation-menu a:hover {
    border: 1px solid #dcdcdc;
  }

  .navigation-menu a:active {
    border: 1px solid #dcdcdc;
    background-color: transparent;
  }

  .navigation-menu.closed {
    width: 0;
  }

  .navigation-bar .navigation-hamburger-menu-container {
    display: flex;
  }
}
