.TeamMember-wrapper {
    margin: 40px 20px 40px 20px;
    position: relative;
}

.TeamMember-wrapper.anim{
    animation: tumbleAnim 0.8s ease-in-out infinite alternate;
}

.TeamMember-innerWrapper {
    width: 200px;
    background-image: linear-gradient(-45deg, #0ab2e6, #0dd09a);
    border-radius: 10px;
    box-shadow: 3px 3px 10px #0f1434;
    padding: 0;

    height: 350px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 1s ease-in-out;
    overflow: hidden;
}

.TeamMember-innerWrapper.hover {
    box-shadow: -4px 4px 9px #0f1434;
    transform: rotateY(180deg);
}

.TeamMember-innerWrapper img {
    width: 100%;
}

.TeamMember-innerWrapper img.fit {
    width: 300px;
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
}

.back > p {
    width: 100%;
    text-align: center;
}

.front {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transform-origin: center;
}

.back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;

    transform: rotateY(-180deg);
}

.front.hidden {
    width: 0;
    height: 0;
    overflow: hidden;
}

.back.hidden {
    width: 0;
    height: 0;
    overflow: hidden;
}

@media screen and (max-width: 1020px) {
    .TeamMember-wrapper {
        margin: 15px;
    }

    .TeamMember-innerWrapper {
        width: 250px;
        height: 450px;
        padding: 3px 0 3px 0;
    }

    .TeamMember-innerWrapper img.fit {
        width: 220px;
        border-radius: 10px;
        height: 100%;
        object-fit: cover;
    }

}

.front > h4, .front > h5 {
    text-align: center;
}

.mouseIcon {
    z-index: 999;
    position: absolute;
    animation: hoveranim 0.8s ease-in-out infinite alternate;
}

@keyframes tumbleAnim {
    0% {
        transform: rotateY(-10deg);
    }
    100% {
        transform: rotateY(10deg);
    }
}

@keyframes hoveranim {
    0% {
        top: -30px;
        left: 20px;
    }

    100% {
        top: 20px;
        left: 50px;
    }
}