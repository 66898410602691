blogpost_title.blogpostcard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;

    padding: 5px 7vw 5px 7vw;
}

.blogpostcard a {
    color: inherit;
}

.blogPostCardWrapper {
    min-width: 400px;
    height: 600px;

    padding: 0;
    margin: 5px 15px 30px 15px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;

    background-image: linear-gradient(40deg, rgb(219, 226, 233), rgb(234, 255, 231));
    box-shadow: 0 1px 6px #0e1331;

    overflow: hidden;
}

.blogPostCardWrapper > img {
    width: 100%;
    height: 50%;
    object-fit: cover;
    position: relative;
}

.blogPostCardWrapper > .blogpost_title {
    width: 100%;
    padding: 10px 10px 10px 0;
    height: 47px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blogpost_title > h6 {
    width: 350px;
    color: #0c112e;
    text-align: center;
    margin: 0;
    font-weight: bold;
    font-size: 17pt;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.blogPostCardWrapper > .blogpost_button {
    width: 30%;
    border-radius: 5px;
    border: 1px solid darkslategrey;
    text-align: center;
    padding: 5px;
    margin: 5px 7px 7px calc(70% - 7px);
}

.blogpost_button:hover {
    background-color: rgba(15, 20, 52, 0.6);
    cursor: pointer;
    color: white;
}

.blogpost_button > a:hover {
    color: white !important;
}

.blogPostCardWrapper > .blogpost_content {
    width: 100%;
    height: 35%;
    margin: 0;

    line-height: 1.6rem;
    padding: 0 20px 0 20px;
    word-wrap: break-word;
    overflow: hidden;
}

.blogPostCardWrapper > p {
    max-height: 100%;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    line-height: 30px;
    margin: 0;
    font-family: Helvetica Neue, sans-serif;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

@media screen  and (max-width: 700px) {
    .blogPostCardWrapper {
        min-width: 300px;
    }

    .blogpost_title > h6 {
        font-size: 14pt;
        width: 300px;
    }
}
