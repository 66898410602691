.contactButton {
    font-size: 12pt;
    font-family: "Helvetica Neue", sans-serif;
    background-color: #0cb586;
    border-radius: 5px;
    padding: 11px 30px 11px 30px;
    width: fit-content;
    color: white;
    font-weight: bold;
}

.contactButton:hover {
    cursor: pointer;
    background-color: #0dd09a;
    box-shadow: 0 1px 2px black;
}

.contactFormModal {
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.contactForm {
    padding: 50px;
    background-color: white;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;

    overflow-y: scroll;
}

.contactForm > div {
    width: 100%;
}

.buttonWrapper {
    width: 100%;
    padding: 0 40% 0 40%;
}

.button {
    text-align: center;
}

@media screen and (max-width: 1000px) {
    .contactFormModal {
        padding: 0;

    }

    .contactForm > h3 {
        font-size: 14pt;
    }

    .contactForm {
        width: 95%;
        padding: 10px;
        height: 98vh;
        max-width: 100%;
    }

}