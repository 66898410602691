footer {
  padding: 35px 20px 0 10px;
  display: flex;
  width: 100%;
  min-height: 300px;
  box-sizing: border-box;
  background-image: linear-gradient(270deg, rgb(0, 42, 163), rgb(0, 33, 91));
  /*background-color: #0A0F25;*/
  color: white;
}

.footerContainer {
  width: 100%;
  margin-top: 20vh;
  padding: 0 5vw 0 5vw;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media screen and (max-height: 400px) {
  .footerContainer {
    margin-top: 40vh;
  }
}

.fullWidthFooterElement {
  width: 100%;
}

.footerElement {
  margin: 10px 5vw 10px 5vw;
}

.copyright {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

footer a, footer a:hover, footer a:visited {
  color: #fc4445 !important;
}
